<template>
  <div>
    <top-nav :banner="globalData.index.banner" navOnly color="#333" isIndex isParent> </top-nav>
    <img src="/static-images/getplan/banner.png" class="banner" alt="" />
    <div class="hover-pop">
      <!-- <div class="hover-pop-back"></div> -->
      <div class="hover-pop-title">获取方案和报价</div>
      <div class="hover-pop-brief">填写信息，我们会在24小时内联系您</div>
      <div
        class="hover-pop-content flex-col"
        :style="{
          background: 'url(/static-images/getplan/bg.png) no-repeat center/contain'
        }"
      >
        <div class="w-full flex gap-40px">
          <div class="hover-pop-left">
            <div class="hover-pop-item">
              <img class="hover-pop-icon hover-pop-name" src="/static-images/getplan/ic_1.png" alt="" />
              <!-- <div class="hover-pop-txt">姓名</div> -->
              <input class="hover-pop-input hover-pop-name-input" type="text" placeholder="请输入您的姓名" />
            </div>
            <div class="hover-pop-item">
              <img class="hover-pop-icon hover-pop-call" src="/static-images/getplan/ic_2.png" alt="" />
              <!-- <div class="hover-pop-txt">联系方式</div> -->
              <input class="hover-pop-input hover-pop-call-input" type="text" placeholder="请输入您的手机号" />
            </div>
            <div class="hover-pop-item">
              <img class="hover-pop-icon hover-pop-company" src="/static-images/getplan/ic_3.png" alt="" />
              <!-- <div class="hover-pop-txt">公司名称</div> -->
              <input class="hover-pop-input hover-pop-company-input" type="text" placeholder="请输入您的公司名称" />
            </div>
            <div class="hover-pop-item">
              <img class="hover-pop-icon hover-pop-plan" src="/static-images/getplan/ic_4.png" alt="" />
              <!-- <div class="hover-pop-txt">方案类型</div> -->
              <select
                class="hover-pop-input hover-pop-plan-input"
                placeholder="请选择您车辆的所属类型"
                :style="{ color: selected ? '#666' : '#969393' }"
              >
                <option selected disabled value="">请选择您的方案类型</option>
                <option value="商砼车">商砼车</option>
                <option value="公务车">公务车</option>
                <option value="物流车">物流车</option>
                <option value="押运车客车">押运车客车</option>
                <option value="危险品车">危险品车</option>
                <option value="环卫车">环卫车</option>
                <option value="执法车">执法车</option>
                <option value="渔船">渔船</option>
                <option value="渣土车">渣土车</option>
                <option value="叉车">叉车</option>
                <option value="校车">校车</option>
                <option value="冷链车">冷链车</option>
                <option value="滴滴车">滴滴车</option>
              </select>
            </div>

            <div class="hover-pop-item">
              <img class="hover-pop-icon hover-pop-plan" src="/static-images/getplan/ic_5.png" alt="" />
              <input class="hover-pop-input hover-pop-check-input" type="text" placeholder="请输入验证码" />
              <img class="hover-pop-check-icon" src="" alt="" />
            </div>
            <div class="private" v-if="false">资料收集<span class="sp">《隐私说明》</span></div>
          </div>
          <div class="hover-pop-right">
            <div class="hover-pop-item hover-pop-item-sp">
              <img class="hover-pop-icon hover-pop-demand" src="/static-images/getplan/ic_6.png" alt="" />
              <!-- <div class="hover-pop-txt">需求描述</div> -->
              <textarea class="hover-pop-input hover-pop-area" placeholder="请在此留下您的具体需求，我们会尽快与您联系。"></textarea>
            </div>
          </div>
        </div>

        <div class="hover-pop-button">提交</div>
      </div>

      <div class="privateBox">
        <div class="private-back"></div>
        <div class="private-content">
          <div class="private-title">
            中航北斗用户隐私说明
            <img class="private-close" src="/static-images/getplan/ic_close.png" />
          </div>
          <div class="private-inner">
            <div class="private-h1">
              我们深知个人信息对您的重要性，我们将按照法律法规规定为您提供更加安全、可靠的服务。本摘要希望向您简洁介绍《中航北斗隐私政策》的核心内容。
            </div>
            <div class="private-h2">1、个人信息收集和使用</div>
            <div class="private-h3">主要功能/场景收集和使用</div>
            <div class="private-h3">
              为了实现特定业务功能，我们需要收集和使用你的部分个人信息，你有权拒绝我们收集和使用你的个人信息。但请理解，如你拒绝我们收集和使用业务功能所必需的个人信息，则将无法享受对应的业务功能。
            </div>
            <div class="private-h2">2、您如何管理自己的信息</div>
            <div class="private-h3">通过产品页面自主操作：</div>
            <div class="private-h3">登录中航北斗官网，进入【方案获取】页面，用户主动填写基础信息；</div>
            <div class="private-h2">3、联系我们</div>
            <div class="private-h3">
              您可以通过在线客服或400-007-8188电话联系我们，或选择给我们发送邮件（发送邮件至scjhy2012@163.com或写信至以下地址：上四川省成都市高新区天府四街199号长虹科技大厦12楼1号、13楼1号。一般情况下，我们将在5个工作日内回复。
            </div>

            <div class="private-button">我已知晓</div>
          </div>
        </div>
      </div>

      <div class="hover-pop-success">
        <div class="private-back"></div>
        <div class="private-content">
          <div class="private-title">
            提示
            <img class="private-close success-close" src="/static-images/getplan/ic_close.png" />
          </div>
          <div class="private-inner">
            <img class="success-img" src="/static-images/getplan/ic_success.png" alt="" />
            <div class="success-txt">提交成功！我们会尽快联系您</div>

            <div class="private-button success-button">确定</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import axios from 'axios';
export default {
  computed: {
    ...mapGetters(['globalData'])
  },
  data() {
    return {
      selected: false
    };
  },
  mounted() {
    // let pop = document.querySelector(".hover-pop");
    // let back = document.querySelector(".hover-pop-back");
    // let successContent = document.querySelector(".hover-pop-success");

    var checkImg = document.querySelector('.hover-pop-check-icon');
    checkImg.addEventListener('click', () => {
      getCode();
    });

    var content = document.querySelector('.hover-pop-content');
    var popName = document.querySelector('.hover-pop-name');
    var popCall = document.querySelector('.hover-pop-call');
    var popCompany = document.querySelector('.hover-pop-company');
    var popPlan = document.querySelector('.hover-pop-plan');
    var popDemand = document.querySelector('.hover-pop-demand');

    // var readPrivate = document.querySelector('.private').querySelector('.sp');
    var close = document.querySelector('.private-close');
    var confirm = document.querySelector('.private-button');
    var privateBox = document.querySelector('.privateBox');

    // readPrivate.addEventListener('click', () => {
    //   privateBox.style.display = 'block';
    // });
    close.addEventListener('click', () => {
      privateBox.style.display = 'none';
    });
    confirm.addEventListener('click', () => {
      privateBox.style.display = 'none';
    });

    let name = document.querySelector('.hover-pop-name-input');
    let call = document.querySelector('.hover-pop-call-input');
    let company = document.querySelector('.hover-pop-company-input');
    let plan = document.querySelector('.hover-pop-plan-input');
    let check = document.querySelector('.hover-pop-check-input');
    let demand = document.querySelector('.hover-pop-area');
    let submit = document.querySelector('.hover-pop-button');

    if (Object.keys(this.$route.params).length > 0) {
      name.value = this.$route.params.name || '';
      call.value = this.$route.params.mobile || '';
      company.value = this.$route.params.company || '';
      plan.value = this.$route.params.plan || '';
      if (plan.value) {
        this.selected = true;
      }
      demand.value = this.$route.params.remark || '';
    }

    plan.addEventListener('change', () => {
      this.selected = true;
    });
    submit.addEventListener('click', () => {
      sendInfo();
    });
    function getCode() {
      axios.get('https://api.dev.zhkcjt.com/support/other/captcha').then(res => {
        checkImg.src = 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(res.data);
      });
    }

    function sendInfo() {
      const tips = [];
      const emptyValue = [];
      if (!name.value) {
        emptyValue.push('姓名');
      }
      if (!call.value) {
        emptyValue.push('联系方式');
      }
      emptyValue.length && tips.push(`请填写 ${emptyValue.join('、')}`);
      if (call.value.length !== 11) {
        tips.push('请输入正确的手机号');
      }
      if (tips.length) {
        return alert(tips.join('\n'));
      }
      axios
        .post(`https://api.dev.zhkcjt.com/support/user/websiteCommit/${check.value}`, {
          name: name.value,
          mobile: call.value,
          remark: demand.value,
          company: company.value,
          plan: plan.value,
          source: GB_SOURCE || '未知',
          type: 'gps'
        })
        .then(res => {
          if (res.data.status !== 1) {
            getCode();
            alert('验证码有误，请重新输入');
          } else {
            // alert("资料提交成功!");
            success_box.style.display = 'block';
            setTimeout(() => {
              window.history.go(0);
            }, 3000);
          }
        });
    }
    getCode();

    let success_box = document.querySelector('.hover-pop-success');
    var success_button = document.querySelector('.success-button');
    let success_close = document.querySelector('.success-close');
    success_close.addEventListener('click', () => {
      success_box.style.display = 'none';
    });
    success_button.addEventListener('click', () => {
      success_box.style.display = 'none';
    });
    // let successImg = document.querySelector(".hover-pop-success-img");
    // successImg.src = `${window.origin}/static-images/success.png`;
    // let successPhone = document.querySelector(".hover-pop-success-phone");
    // successPhone.src = `${window.origin}/static-images/phone.png`;
    // let close1 = document.querySelector(".hover-pop-close1");
    // close1.src = `${window.origin}/static-images/close.png`;

    // let close2 = document.querySelector(".hover-pop-close2");
    // close2.src = `${window.origin}/static-images/close.png`;
    // close1.addEventListener("click", close);
    // close2.addEventListener("click", close);
    // back.addEventListener("click", close);
    // function close() {
    //   pop.style.display = "none";
    //   content.style.display = "block";
    //   successContent.style.display = "none";
    //   name.value = "";
    //   call.value = "";
    //   check.value = "";
    //   demand.value = "";
    // }
  }
};
</script>
<style scoped lang="scss">
.banner {
  width: 100%;
  height: 430px;
  object-fit: cover;
}

.hover-pop {
  //   display: none;
  margin-top: -350px;
  position: relative;
}
.hover-pop-back {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(10, 10, 10, 0.6);
  z-index: 999;
}
.hover-pop-content {
  margin: 0 auto;
  width: 1284px;
  //   height: 612px;
  padding: 80px 80px 80px 484px;
  box-sizing: border-box;
  z-index: 999;
  display: flex;
  align-items: flex-start;
}
.hover-pop-left {
  overflow: hidden;
  flex: 1;
}
.hover-pop-right {
  flex: 1;
}

.hover-pop-title {
  font-weight: 500;
  color: #096dd9;
  font-size: 60px;
  text-align: center;
  font-family: YouSheBiaoTiHei;
  line-height: 1;
}
.hover-pop-brief {
  color: #096dd9;
  font-size: 30px;
  margin-top: 40px;
  text-align: center;
  margin-bottom: 50px;
  line-height: 1;
}
.hover-pop-item {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  color: red;
  border-radius: 4px 4px 4px 4px;
  border: 1px solid #e8e8e8;
  padding: 0 12px;
  height: 50px;
}
.hover-pop-item-sp {
  height: 356px;
  align-items: flex-start;
  padding: 12px;
  box-sizing: border-box;
}
.hover-pop-icon {
  width: 20px;
  flex-shrink: 0;
}
.hover-pop-txt {
  width: 90px;
  font-size: 20px;
  color: #4e99fd;
  margin: 0 16px 0 8px;
  flex-shrink: 0;
  text-align-last: justify;
  position: relative;
  margin-top: 10px;
}
.hover-pop-txt::after {
  content: ':';
  position: absolute;
  left: 100%;
  top: 50%;
  transform: translateY(-50%);
}
.hover-pop-input {
  flex-grow: 1;
  border-radius: 8px 8px 8px 8px;
  border: none;
  font-size: 16px;
  height: 40px;
  padding: 0 20px;
  outline: none;
  color: #666;
  background-color: transparent;
  min-width: 0;
  &::placeholder {
    color: #969393;
  }
}
.hover-pop-check-icon {
  width: 120px;
  height: 40px;
  cursor: pointer;
}

// .hover-pop-demand {
//   width: 138px;
//   height: 40px;
//   font-size: 20px;
//   margin-right: 16px;
//   color: rgba(48, 151, 239, 1);
//   text-align: right;
//   position: relative;
//   align-self: flex-start;
// }
// .hover-pop-demand::after {
//   content: ":";
//   position: absolute;
//   left: 100%;
//   top: 50%;
//   transform: translateY(-50%);
//   height: 100%;
// }
.hover-pop-area {
  height: 100%;
  resize: none;
  box-sizing: border-box;
}
.hover-pop-button {
  font-size: 24px;
  color: white;
  width: 100%;
  height: 48px;
  background: linear-gradient(180deg, #3197f1 0%, #3384e8 100%);
  border-radius: 8px 8px 8px 8px;
  text-align: center;
  line-height: 48px;
  margin: 0 auto;
  margin-top: 40px;
  cursor: pointer;
  letter-spacing: 20px;
}

.hover-pop-close {
  position: absolute;
  width: 16px;
  right: 24px;
  top: 24px;
  cursor: pointer;
}
.private {
  font-size: 18px;
  color: #999999;
  text-align: left;
  margin-top: 58px;
  .sp {
    color: #4e99fd;
    cursor: pointer;
  }
}
.en-bannerTxt {
  font-family: YouSheBiaoTiHei;
  font-size: 60px;
  color: white;
  margin-bottom: 40px;
  text-align: center;
}
.bannerTxt {
  font-size: 30px;
  color: rgba(255, 255, 255, 0.8);
  text-align: center;
}

.privateBox,
.hover-pop-success {
  display: none;
  .private-back {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba($color: #000000, $alpha: 0.6);
    z-index: 999;
  }
  .private-content {
    position: fixed;
    width: 800px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
    .private-title {
      color: white;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 60px;
      background: #4e99fd;
      position: relative;
      .private-close {
        position: absolute;
        right: 24px;
        top: 22px;
        width: 16px;
        cursor: pointer;
      }
    }
    .private-inner {
      font-size: 14px;
      line-height: 2;
      background: white;
      text-align: left;
      padding: 40px;
      padding-top: 10px;
      .private-h1 {
        color: #666;
      }
      .private-h2 {
        color: #333;
      }
      .private-h3 {
        color: #999;
      }
      .private-button {
        width: 144px;
        height: 48px;
        background: linear-gradient(180deg, #4e99fd 0%, #3384e8 100%);
        border-radius: 2px 2px 2px 2px;
        font-size: 20px;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        margin-top: 50px;
        cursor: pointer;
      }
    }
  }
}
.hover-pop-success {
  display: none;
}
.success-img {
  width: 354px;
  margin: 0 auto;
  display: block;
}
.success-txt {
  font-size: 16px;
  color: #52c41a;
  text-align: center;
}
</style>
