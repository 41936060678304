import { render, staticRenderFns } from "./customer-wall.vue?vue&type=template&id=77f52e55&scoped=true"
import script from "./customer-wall.vue?vue&type=script&lang=js"
export * from "./customer-wall.vue?vue&type=script&lang=js"
import style0 from "./customer-wall.vue?vue&type=style&index=0&id=77f52e55&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_4izezhgveghju4kikdy2dserta/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77f52e55",
  null
  
)

export default component.exports