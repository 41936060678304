<template>
  <div>
    <div ref="banner" class="nav" :class="{ white: !color, parent: isParent, navheight: navOnly }" @mouseenter="stopCount" @mouseleave="startCount">
      <div class="nav-container">
        <div class="banner-list" v-if="!navOnly">
          <div
            class="banner-item"
            v-for="(item, index) of banner"
            :style="{
              transform: `translateX(${(index - currentIndex) * 100}%)`,
              background: `url(${item.img}) no-repeat center/cover`
            }"
            :key="index"
          >
            <div class="banner-limit">
              <div class="banner-item-title banner-top" :style="{ color: item.color ? item.color : color }">{{ item.txt }}</div>
              <div class="banner-item-brief" :style="{ color: color || item.color ? 'rgba(51, 51, 51, .8)' : '' }" v-html="item.brief"></div>
              <div class="banner-buttons">
                <div
                  class="banner-button banner-button1"
                  @click="getPlan"
                  :class="{ 'banner-button1-c': color || item.colored }"
                  :style="{
                    color: item.lbc,
                    'border-color': item.lbc
                  }"
                >
                  获取方案
                </div>
                <div
                  class="banner-button banner-button2"
                  @click="chat"
                  :class="{ 'banner-button2-c': color || item.colored }"
                  :style="{
                    color: item.rbc
                  }"
                >
                  立即咨询
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <img class="banner" :src="banner" alt="" /> -->
        <div class="content" :class="{ scrolling: getDown }">
          <div class="content-wrap">
            <div class="content-inner">
              <img class="logo" :src="color || getDown ? logo : whiteLogo" alt="" />
              <div class="tabs">
                <div class="query">
                  <div class="list" v-if="showNav">
                    <div class="item" v-for="(item, index) of globalData.common.navs" :key="index" :class="{ active: bannerIndex == index }">
                      <div ref="tabs" class="inner" @click="nav(index)">
                        {{ item.name }}
                      </div>
                      <div class="child" :class="{ 'child-sp': item.sp }" v-if="typeof item.route === 'object'">
                        <div class="child-inner">
                          <div class="child-content">
                            <div class="child-list" v-for="(outerItem, outerIndex) of item.route" :key="outerIndex">
                              <div
                                class="child-parent"
                                @mousedown="parentNav(index, outerIndex)"
                                :class="{
                                  'child-active': bannerChildIndex == outerIndex
                                }"
                              >
                                <img class="child-icon" :src="item.sp ? centericon[outerIndex] : navicon[outerIndex]" alt="" />
                                {{ outerItem.name }}
                              </div>
                              <!-- <div
                              class="child-item"
                              :class=""
                              @mousedown="
                                childNav(index, outerIndex, innerIndex)
                              "
                              v-for="(
                                innerItem, innerIndex
                              ) of outerItem.children"
                              :key="innerIndex"
                            >
                              {{ innerItem.name }}
                            </div> -->
                            </div>
                          </div>
                          <!-- <div
                          class="child-item"
                          :class="{
                            'child-active': bannerChildIndex == innerIndex,
                          }"
                          @mousedown="childNav(index, innerIndex)"
                          v-for="(innerItem, innerIndex) of item.route"
                          :key="innerIndex"
                        >
                          {{ innerItem.name }}
                        </div> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="phone-content">
                  <div class="phone">
                    全国服务热线
                    <img class="phone-icon sale-ani" :src="color || getDown ? phone : whitePhone" alt="" />
                    <div class="phone-num">{{ globalData.common.navPhone }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="hover content-limit" v-if="typeof imgList === 'string'">
        <div
          class="txt"
          :style="{ 'text-align': isParent ? 'center' : 'left' }"
        >
          <slot></slot>
          <div
            class="now-button"
            @click="$bus.$emit('online-chat')"
            v-if="!hideButton"
          >
            立即咨询
            <img
              src="../asset/index/ic_index_48.png"
              class="more-icon"
              alt=""
            />
          </div>
        </div>
      </div> -->
        <div class="indicator" v-if="banner.length > 1">
          <div
            class="indicator-item"
            :class="{ 'indicator-item-active': currentIndex === index }"
            v-for="(item, index) of banner"
            :key="index"
            @click="setCurrentIndex(index)"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex';
import storage from '../utils/utils.js';
import logo from '../asset/index/csb_index_16.png';
import whiteLogo from '../asset/index/csb_index_15.png';
import phone from '../asset/index/csb_index_14.png';
import whitePhone from '../asset/index/csb_index_13.png';

import navic1 from '../asset/index/csb_index_1.png';
import navic2 from '../asset/index/csb_index_2.png';
import navic3 from '../asset/index/csb_index_3.png';
import navic4 from '../asset/index/csb_index_4.png';
import navic5 from '../asset/index/csb_index_5.png';
import navic6 from '../asset/index/csb_index_6.png';
import navic7 from '../asset/index/csb_index_7.png';
import navic8 from '../asset/index/csb_index_8.png';
import navic9 from '../asset/index/csb_index_9.png';
import navic10 from '../asset/index/csb_index_10.png';
import navic11 from '../asset/index/csb_index_11.png';
import navic12 from '../asset/index/csb_index_12.png';
import navic13 from '../asset/index/csb_index_17.png';
import navic14 from '../asset/index/csb_index_18.png';

import centeric1 from '../asset/index/csb_center_1.png';
import centeric2 from '../asset/index/csb_center_2.png';
import centeric3 from '../asset/index/csb_center_3.png';
import centeric4 from '../asset/index/csb_center_4.png';

export default {
  data() {
    return {
      showNav: true,
      onPhone: false,
      logo,
      whiteLogo,
      phone,
      whitePhone,
      currentIndex: 0,
      getDown: false,
      centericon: [centeric1, centeric2, centeric3, centeric4],
      navicon: [navic1, navic2, navic3, navic4, navic5, navic6, navic7, navic8, navic9, navic10, navic11, navic12, navic13, navic14]
    };
  },
  props: {
    banner: {
      type: [String, Array]
    },
    isParent: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: ''
    },
    isIndex: {
      type: Boolean,
      default: false
    },
    navOnly: {
      type: Boolean,
      default: false
    }
    // hideButton: {
    //   type: Boolean,
    //   default: false,
    // },
  },
  methods: {
    getPlan() {
      this.$router.push('/getPlan');
    },
    chat() {
      this.$chat();
    },
    startCount() {
      this.timer = setInterval(() => {
        if (this.currentIndex < this.banner?.length - 1) {
          this.currentIndex++;
        } else {
          this.currentIndex = 0;
        }
      }, 5000);
    },
    stopCount() {
      clearInterval(this.timer);
    },
    setCurrentIndex(index) {
      this.currentIndex = index;
    },
    // ...mapMutations({
    //   setBannerIndex: "setBannerIndex",
    //   setBannerChildIndex: "setBannerChildIndex",
    // }),
    call(number) {
      window.location.href = `tel:${number}`;
    },
    getSize() {
      let width = document.documentElement.clientWidth;
      if (width <= 767) {
        this.showNav = false;
        this.onPhone = true;
      } else {
        this.showNav = true;
        this.onPhone = false;
      }
    },
    switchNav() {
      this.showNav = !this.showNav;
    },
    hideNav(e) {
      if (this.onPhone && !this.$refs?.tabs?.includes?.(e.target)) {
        this.showNav = false;
      }
    },
    nav(index) {
      let url = this.globalData.common.navs[index].route;
      let path = this.globalData.common.navs[index].path;
      if (path) {
        this.$router.push(path);
      } else {
        if (typeof url === 'string') {
          // this.setBannerIndex(index);
          // this.setBannerChildIndex(null);
          // storage.sStorage.set("navIndex", index);
          // storage.sStorage.set("navChildIndex", null);
          this.$router.push(url);
        } else if(Array.isArray(url)) {
            this.$router.push(url[0].path);
        }
      }
    },
    parentNav(index, outerIndex) {
      let url = this.globalData.common.navs[index].route[outerIndex].path;
      if (url) {
        this.$router.push(url);
      }
    },
    childNav(index, outerIndex, innerIndex) {
      let url = this.globalData.common.navs[index].route[outerIndex].children[innerIndex].path;
      // this.setBannerIndex(index);
      // this.setBannerChildIndex(innerIndex);
      // storage.sStorage.set("navIndex", index);
      // storage.sStorage.set("navChildIndex", innerIndex);
      this.$router.push(url);
    }
    // getCurrentNavIndex() {
    //   let currentUrl = this.$route.path;
    //   this.globalData.common.navs.forEach((v, i) => {
    //     if (typeof v.route !== "string") {
    //       v.route.forEach((iv, ii) => {
    //         if (currentUrl === iv.path) {
    //           this.setBannerIndex(i);
    //           this.setBannerChildIndex(ii);
    //         }
    //       });
    //     } else {
    //       if (currentUrl === v.route) {
    //         this.setBannerIndex(i);
    //         this.setBannerChildIndex(null);
    //       }
    //     }
    //   });
    // },
  },
  computed: {
    // ...mapState({
    //   bannerIndex: "bannerIndex",
    //   bannerChildIndex: "bannerChildIndex",
    // }),
    ...mapGetters(['globalData']),
    bannerIndex() {
      return this.globalData.common.navs.findIndex(which => {
        if (typeof which.route === 'string') {
          return which.route === this.$route.fullPath;
        } else {
          return (
            which.path === this.$route.fullPath ||
            which.route.findIndex(item => {
              if (item.path !== this.$route.fullPath) {
                if (item.children) {
                  return (
                    item.children.findIndex(i => {
                      return i.path === this.$route.fullPath;
                    }) > -1
                  );
                } else {
                  return false;
                }
              } else {
                return true;
              }
            }) > -1
          );
        }
      });
    },
    bannerChildIndex() {
      if (this.bannerIndex === -1) return -1;
      const navs = this.globalData.common.navs || [];
      const nav = navs[this.bannerIndex];
      if (Array.isArray(nav.route)) {
        return nav.route.findIndex(item => item.path === this.$route.fullPath);
      }
      return -1;
    }
  },
  mounted() {
    // this.setBannerIndex(storage.sStorage.get("navIndex") || 0);
    // this.setBannerChildIndex(storage.sStorage.get("navChildIndex") || null);
    // this.getCurrentNavIndex();
    window.addEventListener('resize', this.getSize);
    this.getSize();
    window.addEventListener('mousedown', this.hideNav);

    window.addEventListener('scroll', () => {
      if (this.$refs.banner) {
        if (document.documentElement.scrollTop > 0) {
          this.getDown = true;
        } else {
          this.getDown = false;
        }
      }
    });

    // this.timer = setInterval(() => {
    //   if (this.currentIndex < this.banner?.length - 1) {
    //     this.currentIndex++;
    //   } else {
    //     this.currentIndex = 0;
    //   }
    // }, 5000);
  },
  destroyed() {
    window.removeEventListener('resize', this.getSize);
    window.removeEventListener('mousedown', this.hideNav);
  }
};
</script>

<style scoped lang="scss">
.nav {
  font-size: 0;
  position: relative;
  // padding-top: 100px;
  width: 100%;
  // height: 700px;
  .item {
    color: #333;
  }
}

.parent {
  // height: 800px;
  //   aspect-ratio: 1920/800;
  position: relative;
  width: 100%;
  padding-top: 41.67%;
}
.nav-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.navheight {
  height: 100px;
  padding-top: 0;
}
.banner {
  width: 100%;
}

.content {
  top: 0;
  left: 0;
  right: 0;
  position: fixed;
  z-index: 999;
  height: 100px;
  transition: 0.5s all;
  .content-wrap {
    margin: 0 auto;
  }
}

.content-inner {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo {
  width: 232px;
  pointer-events: none;
}

.tabs {
  display: flex;
  align-items: stretch;
  justify-content: flex-end;
}

.list {
  color: white;
  font-size: 24px;
  display: flex;
  align-items: stretch;
  height: 100px;
}

.query {
  margin-left: 80px;

  .button {
    width: 133px;
    height: 100px;
    display: none;
    cursor: pointer;

    // .button-icon {
    //   width: 70px;
    //   @media (max-width: 767px) {
    //     width: 40px;
    //   }
    // }
  }
}

.phone-content {
  display: flex;
}

.phone {
  font-size: 24px;
  color: #333;
  display: flex;
  align-items: center;
  margin-left: 60px;
  white-space: nowrap;

  .phone-icon {
    width: 26px;
    margin-right: 8px;
    margin-left: 30px;
  }
}

.white {
  .phone {
    color: white;
  }
}
.item {
  padding: 0 32px;
  height: 100px;
  line-height: 1;
  position: relative;
  cursor: pointer;
  font-size: 24px;
  color: #666666;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  .inner {
    width: 100%;
    position: relative;
    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 4px;
      background: transparent;
      top: 100%;
      left: 0;
      margin-top: 8px;
    }
  }

  &:hover {
    .child {
      display: block;
    }
  }

  .child {
    position: absolute;
    top: 100%;
    padding-top: 16px;
    left: 32px;
    width: 800px;
    display: none;

    .child-inner {
      box-shadow: 2px 2px 5px #999;
      border-radius: 4px;
      .child-content {
        background: #ffffff;
        border-radius: 4px 4px 4px 4px;
        padding: 24px;
        display: flex;
        flex-wrap: wrap;
        gap: 40px 0;
        position: relative;
        .child-list {
          width: 33%;
          .child-parent {
            display: flex;
            align-items: center;
            gap: 8px;
            font-size: 18px;
            color: #333333;
            margin-bottom: 12px;
            .child-icon {
              width: 18px;
            }
            &:hover {
              color: #4e99fd;
            }
          }
          .child-item {
            font-size: 14px;
            color: #999999;
            line-height: 22px;
            &:hover {
              color: #4e99fd;
            }
          }
          .child-active {
            color: #4e99fd;
          }
        }
        &::after {
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          border-left: 12px solid transparent;
          border-right: 12px solid transparent;
          border-bottom: 14px solid white;
          bottom: 100%;
          left: 36px;
        }
      }
    }

    // .child-item {
    //   padding: 0 14px;
    //   height: 64px;
    //   font-size: 16px;
    //   color: white;
    //   display: flex;
    //   align-items: center;
    //   justify-content: center;
    //   font-weight: normal;
    //   margin-bottom: 4px;
    //   border-radius: 4px;

    //   &:hover {
    //     background: rgba(255, 255, 255, 0.2);
    //   }

    //   &:last-child {
    //     margin-bottom: 0;
    //   }
    // }

    // .child-active {
    //   background: rgba(255, 255, 255, 0.2);
    // }
  }
  .child-sp {
    width: 200px;
    .child-list {
      width: 100% !important;
    }
  }
}

.item:hover,
.active {
  color: #333;
  .inner::after {
    background: #4e99fd;
  }
}
.white {
  .item {
    color: white;
  }
  .item:hover,
  .active {
    .inner::after {
      background: white;
    }
  }
}

.hover {
  position: absolute;
  left: 50%;
  top: 100px;
  height: calc(100% - 100px);
  transform: translateX(-50%);
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  justify-content: center;

  .txt {
    margin-top: 120px;
    line-height: 1;
    padding: 0 20px;
  }

  .digital {
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    transform: translateY(-50%);
  }
}

.banner-list {
  width: 100%;
  height: 100%;
  position: relative;
  .banner-item {
    width: 100%;
    height: 100%;
    position: absolute;
    transition: 0.5s all;
    text-align: left;
    .banner-limit {
      max-width: 1920px;
      margin: 0 auto;
      padding: 0 8.5%;
      box-sizing: border-box;
      position: relative;
      top: 25%;
      .banner-buttons {
        display: flex;
        align-items: center;
        gap: 40px;
      }
      .banner-button {
        font-size: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 230px;
        height: 64px;
        border-radius: 40px;
        margin-top: 30px;
        cursor: pointer;
        font-weight: bold;
        box-sizing: border-box;
      }
      .banner-button1 {
        background-color: transparent;
        color: white;
        border: 1px solid white;
      }
      .banner-button1-c {
        border-color: #096dd9;
        color: #096dd9;
      }
      .banner-button2 {
        color: rgba(9, 109, 217, 1);
        background-color: white;
      }
      .banner-button2-c {
        color: white;
        background-color: #096dd9;
      }
    }
  }
}
.indicator {
  position: absolute;
  bottom: 64px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  gap: 24px;
  .indicator-item {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #ffffff;
    opacity: 0.4;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
  }
  .indicator-item-active {
    opacity: 0.8;
  }
}

.scrolling {
  background: white;
  box-shadow: 0 1px 10px #999;
  .phone {
    color: #333;
  }
  .item {
    color: #666;
  }
  .item:hover,
  .active {
    .inner::after {
      background: #4e99fd;
    }
  }
}
.banner-top {
  // margin-top: 200px;
}
</style>
<style lang="scss">
.banner-item-title {
  color: #4e99fd;
  font-size: 72px;
  width: 100%;
  font-family: YouSheBiaoTiHei;
}
.banner-item-brief {
  color: #666666;
  font-size: 30px;
  line-height: 2;
  display: inline-block;
  text-align: left;
  margin: 0;
  margin-top: 1em;
}
.white {
  .banner-item-title {
    color: white;
    font-size: 72px;
    width: 100%;
    font-family: YouSheBiaoTiHei;
  }
  .banner-item-brief {
    color: rgba(255, 255, 255, 0.8);
    font-size: 30px;
    line-height: 2;
    display: inline-block;
    text-align: left;
    margin: 0;
    margin-top: 1em;
  }
}
.banner-item-sp {
  font-size: 88px;
  font-family: YouSheBiaoTiHei;
}
.now-button {
  width: 217px;
  height: 61px;
  background: #4e99fd;
  border-radius: 2px 2px 2px 2px;
  font-size: 24px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  margin-top: 30px;
  cursor: pointer;
  .more-icon {
    width: 30px;
  }
}
</style>
