import { render, staticRenderFns } from "./support-service.vue?vue&type=template&id=679060b4&scoped=true"
import script from "./support-service.vue?vue&type=script&lang=js"
export * from "./support-service.vue?vue&type=script&lang=js"
import style0 from "./support-service.vue?vue&type=style&index=0&id=679060b4&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_4izezhgveghju4kikdy2dserta/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "679060b4",
  null
  
)

export default component.exports